<template>
  <div>
    <v-row class="pa-8">
      <v-col cols="6" sm="4" md="3" lg="2" v-for="file in files" :key="file.id">
        <v-card
          class="rounded-xl d-flex flex-column justify-space-between"
          elevation="4"
          height="300"
          target="_blank"
          :href="file.url"
        >
          <v-card-title class="py-6">
            <v-icon x-large :color="file.color">{{ file.icon }}</v-icon>
            <v-spacer></v-spacer>
            <!-- <v-icon>mdi-dots-vertical</v-icon> -->
          <v-btn
            style="height: 34px;padding: 0px 6px;min-width: 0px; right: 1%;"
            dark
            color="darkblue"
            rounded
            @click.stop.prevent="folderedit(file)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            style="height: 34px;padding: 0px 6px;min-width: 0px;"
            dark
            color="error"
            rounded
            @click.stop.prevent="DeleteConfirm(file)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
          </v-card-title>
          <v-card-text class="mt-6">
            <div
              class="text-subtitle-2 text-sm-h6 black--text font-weight-bold"
            >
              {{ file.name }}
            </div>
            <div
              class="font-weight-medium"
              style="font-size: 0.8rem"
              v-text="formatDate(file.updatedAt)"
            />
          </v-card-text>
          <v-card-actions class="grey lighten-3 pa-4">
            <span
              v-text="getByteSize(file.size)"
              class="font-weight-medium"
              style="font-size: 0.9rem"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6" sm="4" md="3" lg="2">
        <v-card
          class="rounded-xl d-flex flex-column justify-space-around"
          elevation="4"
          height="300"
          style="cursor: pointer"
        >
          <div class="d-flex flex-column align-center justify-center" @click="newFolderDialog = true">
            <v-icon class="mb-2 text-h3">mdi-plus</v-icon>
            <div class="text-body-1 text-sm-h6 text--secondary">
             {{ $t('treinamento.treinamento_adicionar') }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-model="newFolderDialog" width="800">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_novapasta') }}</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="newFolder">
            <v-file-input
              v-model="formFolder.files"
              label="Arquivos"
              prepend-icon=""
              placeholder="Insira aqui seus arquivos"
              prepend-inner-icon="mdi-paperclip"
              multiple
              color="darkblue"
              :rules="[rules.file]"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="newFolderDialog = false">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="registerFiles"
            :loading="loading"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="FileDelete" width="25%">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_excluir') }}</v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="FileDelete = false">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="DeleteFile"
            :loading="loading"
          >
            {{ $t('botoes.botao_aceitar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-row>
    <v-dialog v-model="newFolderDialog" width="800">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_adicionar') }}</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="newFolder">
            <v-file-input
              v-model="formFolder.files"
              label="Arquivos"
              prepend-icon=""
              placeholder="Insira aqui seus arquivos"
              prepend-inner-icon="mdi-paperclip"
              multiple
              color="darkblue"
              :rules="[rules.file]"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="newFolderDialog = false">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="addNewFiles"
            :loading="loading"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editFileDialog" width="800">
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">{{ $t('treinamento.treinamento_novapasta') }}</v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="newFolder">
            <v-text-field
              v-model="editFiles.name"
              label="Nome da Pasta"
              color="darkblue"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="darkpink" text rounded @click="fileCancel()">
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            color="darkpink"
            text
            rounded
            @click="EditFile()"
            :loading="loading"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  props: ["folderId"],
  data() {
    return {
      newFolderDialog: false,
      editFileDialog: false,
      page: "folders",
      formFolder: {},
      editFiles: {},
      files: [],
      loading: false,
      FileDelete: false,
      uri: {},
      rules: {
      email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        file: (v) => !!v || this.$t('regras.regra_file'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
    };
  },
  created() {
    this.getFiles();
  },
  methods: {
    getType() {
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];

        switch (element.type) {
          case ".pdf":
            element.icon = "mdi-file-pdf-box";
            element.color = "#A60000";
            break;
          case ".docx":
          case ".docm":
          case ".dotx":
          case ".dotm":
          case ".txt":
            element.icon = "mdi-file-word-box";
            element.color = "#0F3C8A";
            break;
          case ".xlsx":
          case ".xlsm":
          case ".xltx":
          case ".xltm":
          case ".xlsb":
          case ".xlam":
            element.icon = "mdi-file-excel-box";
            element.color = "#1E6C41";
            break;
          case ".pptx":
          case ".pptm":
          case ".potx":
          case ".potm":
          case ".ppam":
          case ".ppsx":
          case ".ppsm":
          case ".sldx":
          case ".sldm":
          case ".thmx":
            element.icon = "mdi-file-powerpoint-box";
            element.color = "#B43416";
            break;
          case ".mp4":
          case ".mov":
          case ".wmv":
          case ".avi":
          case ".avchd":
          case ".flv":
          case ".f4v ":
          case ".swf":
          case ".mkv":
          case ".webm":
          case ".html5":
          case ".mpeg-2":
            element.icon = "mdi-play-box";
            element.color = "#C31E1D";
            break;
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".bmp":
          case ".psd":
          case ".tiff":
          case ".svg":
          case ".raw":
          case ".webp":
            element.icon = "mdi-image";
            element.color = "#0B9E4C";
            break;
          case ".zip":
          case ".arj":
          case ".cab":
          case ".rar":
          case ".tar":
          case ".z":
          case ".gz":
          case ".taz ":
          case ".tgz":
          case ".gzip":
            element.icon = " mdi-zip-box";
            element.color = "#754668";
            break;
          default:
            element.icon = "mdi-file";
            element.color = "darkblue";
            break;
        }
      }
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      let options = {
        timeZone: "America/Sao_Paulo",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      let localDateString = date.toLocaleString("pt-BR", options);
      return localDateString;
    },
    async getFiles() {
      let files = await axios.get(
        `${url}/trainingFiles/folder/${this.folderId}`,
        authorization
      );
      this.files = files.data;

      this.getType();
    },

    DeleteConfirm(url){
      this.uri = url
      console.log(this.uri)
      this.FileDelete = true
      
    },

    folderedit(file){
      this.editFiles = file
      console.log(this.editFiles)
      this.editFileDialog = true
    },

    fileCancel(){
      this.editFileDialog = false
      this.getFiles()
    },

    async EditFile(){
      let files = await axios.put(
        `${url}/trainingFiles/${this.editFiles.id}`, this.editFiles,
        authorization,
      );
      console.log(files)
      this.editFileDialog = false
      this.getFiles()
    },


    async DeleteFile(){
      let files = await axios.delete(
        `${url}/trainingFiles/${this.uri.id}`,
        authorization
      );
      console.log(files)
      this.FileDelete = false
      this.getFiles()
    },

    async registerFiles() {
      for (let index = 0; index < this.formFolder.files.length; index++) {
        const element = this.formFolder.files[index];
        try {
          let formData = new FormData();
          formData.append("file", element, element.name);

          let response = await axios.post(
            `${url}/lessonsExtras/files`,
            formData
          );
          let link = response.data.url;

          let elem = element.name.split(".");
          let name = elem[0];
          let type = "." + elem[1];

          let body = {
            trainingFolderId: this.folderId,
            name: name,
            type: type,
            size: element.size,
            url: link,
          };

          await axios.post(`${url}/trainingFiles`, body, authorization);
        } catch (error) {
          console.error(error);
        }
      }
    },

    teste() {
      let req = new XMLHttpRequest();
      let formData = new FormData();
      formData.append("name", name);
      formData.append("file", this.fileChoosed);
      req.open(
        "POST",
        localStorage.getItem("iplocal") + ":1337/api/files/copy"
      );
      req.setRequestHeader("token", this.globalFunc.Variables("token"));
      req.send(formData);
    },
    getByteSize(byte_value) {
      let converted_value = null;
      let data_type = "";
      if (byte_value < 1024) {
        data_type = "bytes";
        converted_value = byte_value;
      } else if (byte_value < 1024 * 1024) {
        data_type = "KB";
        converted_value = (byte_value / 1024).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024) {
        data_type = "MB";
        converted_value = (byte_value / (1024 * 1024)).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024 * 1024) {
        data_type = "GB";
        converted_value = (byte_value / (1024 * 1024 * 1024)).toFixed(2);
      } else {
        data_type = "TB";
        converted_value = (byte_value / (1024 * 1024 * 1024 * 1024)).toFixed(2);
      }
      let response = converted_value + " " + data_type;
      return response;
    },

    async addNewFiles() {
      let validation = this.$refs.newFolder.validate();

      if (validation) {
        this.loading = true;

        await this.registerFiles();

        this.$refs.newFolder.reset();
        this.newFolderDialog = false;
        this.getFiles();
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>

.buttonDel {
  height: 34px;
  padding: 0px 6px;
}

</style>
