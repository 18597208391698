<template>
  <li :class="{ selected: isSelected }" @click="selectTicket">
    {{ ticket.userName }}
  </li>
</template>

<script>
export default {
  props: {
    ticket: Object,
    isSelected: Boolean,
  },
  methods: {
    selectTicket() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

li.selected {
  background-color: #007bff;
  color: white;
}
</style>
