var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backcolor ocult"},[_c('v-toolbar',{staticClass:"elevation-1 my-1"},[_c('v-icon',{staticClass:"text-h4",attrs:{"color":"pink"}},[_vm._v("mdi-view-dashboard")]),_c('v-toolbar-title',{staticClass:"ml-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.smAndUp
              ? 'letter-spacing: 0.5rem'
              : 'letter-spacing: 0.2rem')},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_dashbord'))+" ")]),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.formatedDate)+" ")])])]),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateForFilter,"transition":"scroll-x-reverse-transition","offset-y":"","left":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateForFilter=$event},"update:return-value":function($event){_vm.dateForFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-sm-2 mr-md-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.dataPicker),callback:function ($$v) {_vm.dataPicker=$$v},expression:"dataPicker"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","color":"blue","max":_vm.maxMonth,"header-color":"darkblue"},on:{"change":function($event){return _vm.selectDate(_vm.dateForFilter)}},model:{value:(_vm.dateForFilter),callback:function ($$v) {_vm.dateForFilter=$$v},expression:"dateForFilter"}})],1)],1),_c('v-row',{staticClass:"px-4 py-4 px-md-8 py-md-6 printing"},[_c('v-col',{attrs:{"cols":"12","lg":"8","order-md":"1"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"min-height":"45vh"}},[_c('v-card-title',{style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('school.school_numero'))+"  "),_c('b',[_vm._v(_vm._s(_vm.currentMonth))])]),_c('v-card-text',[_c('MonthChart',{ref:"chartMonth",attrs:{"chartData":_vm.chartData}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","order-md":"3","order-lg":"2"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"grid-container pa-4"},[_c('div',{staticClass:"rounded-lg grid-item pink-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"darkpink"}},[_vm._v(" mdi-account-group ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('school.school_acesso'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.totalAccess)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item cyan-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"darkcyan"}},[_vm._v(" mdi-bag-personal ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_menu.menu11'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.accessNumbers.student)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item green-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"green"}},[_vm._v(" mdi-account-tie ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_menu.menu10'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.accessNumbers.teacher)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item yellow-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"yellow"}},[_vm._v(" mdi-school ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('login.login_usuario.usuario4'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.accessNumbers.school)+" ")])],1)])])],1),_c('v-col',{attrs:{"cols":"12","lg":"12","order-md":"4","order-lg":"3"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"min-height":"45vh"}},[_c('v-card-title',{style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('admin.admin_acessorecentealuno'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.recentData,"hide-default-footer":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }