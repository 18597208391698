<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="green" class="text-h4">mdi-account-tie</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ 'Serial' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" dark rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ 'Gerar Serial' }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ 'Gerador de Serial' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formClient.quant"
                      :label="'Quantidade de serial'"
                      :rules="[rules.required, rules.Number]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!editMode">
                      <v-autocomplete
                        color="blue"
                        item-color="darkblue"
                        :items="courseQuery"
                        v-model="formClient.course"
                        :hint="$t('descricao.descricao_curso')"
                        persistent-hint
                        :label="$t('label.label_curso')"
                        item-value="id"
                        item-text="name"
                        :rules="[rules.option]"
                      ></v-autocomplete>
                    </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              Gerar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('label.label_pesquisa')"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="serial"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
import * as XLSX from 'xlsx';
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      userExisting: false,
      courseQuery: [],
      form: {},
      dialog: null,
      showPassword: false,
      formClient: {},
      search: "",
      headers: [
        { text: "Serial Ativo", value: "serial" },
        { text: this.$t('school.school_curso'), value: "Course.name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "student.name" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      serial: [],
      friends: [],
      codes: [],
      courses: [],
      dialogAddCourse: null,
      editedIndex: -1,
      errorSnackbar: false,
      successSnackbar: false,
      snackbar: false,
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        Number: (v) => !!(v || "").match(/^[0-9]+$/) || this.$t('regras.regra_aula'),
        // userExist: (v) =>
        //   (v && !this.checkUserExistence(v)) || "Nome de usuário já existe",

        none: true,
      },
      nameSchool: null,
      schoolName: [],
      schools: [],
      codeSet: new Set()
    };
  },
  
  methods: {
    generateRandomCode() {
      const digits = '0123456789';
      let code = '';
      for (let i = 0; i < 12; i++) {
        code += digits.charAt(Math.floor(Math.random() * digits.length));
      }
      return code;
    },
    generateCodes(number) {
      this.codes = [];
      this.codeSet.clear();
      const numberOfCodes = number; // Defina o número de códigos que você deseja gerar

      while (this.codes.length < numberOfCodes) {
        const newCode = this.generateRandomCode();
        if (!this.codeSet.has(newCode)) {
          this.codes.push(newCode);
          this.codeSet.add(newCode);
          let body = {
            serial: newCode,
            courseId: this.formClient.course
          }
          this.registerSerial(body)
        }
      }
    },
    getSerial() {
      axios
        .get(`${url}/serial`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.serial = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getSerialAll() {
      axios
        .get(`${url}/serialSR`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          for (let index = 0; index < res.data.length; index++) {
            this.codeSet.add(res.data[index].serial)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getCurso() {
      await axios
        .get(`${url}/courses/1`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courseQuery = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    registerSerial(body) {
      axios
        .post(`${url}/serial`, body, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getSerial()
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateClient(id) {
      axios
        .put(`${url}/clients/${id}`, this.formClient, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getSerial();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    savePermission() {
      let coursesIds = [];
      for (let i = 0; i < this.friends.length; i++) {
        for (let j = 0; j < this.courses.length; j++) {
          if (this.friends[i] == this.courses[j].name) {
            coursesIds.push(this.courses[j].id);
          }
        }
      }
      const data = { clientId: this.editedIndex, courseId: coursesIds };

      axios
        .post(`${url}/clientCoursePermissions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeAdd();
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeAdd() {
      this.friends = [];
      this.dialogAddCourse = false;
    },
    addOpen(item) {
      this.friends = [];
      this.editedIndex = item.id;
      this.dialogAddCourse = true;
      axios
        .get(`${url}/clientCoursePermissions?clientId=${item.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.forEach((element) => {
            this.friends.push(element.Course.name);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async saveBtn() {
      let validation = this.$refs.form.validate();

      if (validation) {
        await this.generateCodes(this.formClient.quant)
        this.exportToExcel()
        this.dialog = false;
        this.editMode = false;
        this.$refs.form.reset();
        this.successSnackbar = true;
      }
    },
    getcourses() {
      axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.form.reset();
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formClient = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },

    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.codes.map(code => ({ Serial: code })));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Códigos');
      XLSX.writeFile(workbook, 'serial.xlsx');
    },

    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    // async checkUserExistence(v) {
    //   let response = await axios.get(`${url}/clients/user?user=${v}`, {
    //     headers: {
    //       authorization: token,
    //     },
    //   });
    //   this.userExisting = response.data;
    //   if (this.userExisting == null) {

    //     return this.userExisting === v;
    //   } else {

    //     return this.userExisting.username === v;
    //   }
    // },
  },
  created() {
    this.getSerial();
    this.getCurso();
    this.getSerialAll();
  },
};
</script>
