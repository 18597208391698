<template>
  <v-app id="inspire">
    <v-app-bar
      app
      :height="$vuetify.breakpoint.mobile ? '50px' : '40px'"
      class="darkcolor"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer />
      {{ $t('admin.admin_title') }}
      <v-spacer />
      <v-btn icon disabled> </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      class="blue-grey darken-4"
      dark
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      :expand-on-hover="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list>
        <div v-for="(item, index) in menu" :key="index">
          <v-list-group no-action :prepend-icon="item.icon" color="blue">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subitem, index) in item.children"
              :key="index"
              link
              @click="
                (page = subitem),
                  $vuetify.breakpoint.smAndUp ? '' : (drawer = !drawer)
              "
            >
              <v-list-item-title :v-text="subitem" @click="page = subitem">
                {{ subitem }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
        <div class="logoutStyle">
          <v-list-item @click="logoutDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('botoes.botao_sair') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="logoutDialog" persistent max-width="320">
      <v-card elevation="15" class="rounded-xl py-4 px-2">
        <v-img src="../assets/HelloCircle2.svg" width="85px" class="mx-auto" />
        <v-card-title style="font-size: 1.1rem" class="mt-n3">
          {{ $t('admin.admin_sair.sair1') }}
        </v-card-title>
        <v-card-text style="font-size: 0.9rem" class="mt-n2">
          {{ $t('admin.admin_sair.sair2') }}
        </v-card-text>
        <v-card-actions class="mt-n2">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="pb-3">
              <v-btn
                rounded
                color="darkblue"
                large
                dark
                block
                @click="logout()"
              >
                {{ $t('botoes.botao_sair') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                rounded
                large
                color="darkblue"
                dark
                block
                @click="logoutDialog = false"
              >
                {{ $t('botoes.botao_cancelar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <!-- Aqui -->
      <Kits v-if="page == $t('admin.admin_menu.menu1')" />
      <AcessoRapido v-if="page == $t('admin.admin_menu.menu36')" />
      <ConteudoRP v-if="page == $t('admin.admin_menu.menu37')" />
      <Courses v-if="page == $t('admin.admin_menu.menu2')" />
      <Classes v-if="page == $t('admin.admin_menu.menu3')" />
      <ClassesPlan v-if="page == $t('admin.admin_menu.menu33')" />
      <PlanoProfessor v-if="page == $t('admin.admin_menu.menu34')" />
      <ConteudoAula v-if="page == $t('admin.admin_menu.menu35')" />
      <Homework v-if="page == $t('admin.admin_menu.menu4')" />
      <Training v-if="page == $t('admin.admin_menu.menu5')" />
      <Exercises v-if="page == $t('admin.admin_menu.menu6')" />
      <Events v-if="page == $t('admin.admin_menu.menu7')" />
      <Clients v-if="page == $t('admin.admin_menu.menu8')" />
      <Schools v-if="page ==  $t('admin.admin_menu.menu9')" />
      <Teachers v-if="page ==  $t('admin.admin_menu.menu10')" />
      <Student v-if="page ==  $t('admin.admin_menu.menu11')" />
      <!-- <Directors v-if="page == 'Diretores'" /> -->
      <Managers v-if="page == $t('admin.admin_menu.menu12')" />
      <Teams v-if="page == $t('admin.admin_menu.menu13')" />
      <UserChart v-if="page == $t('admin.admin_menu.menu14')" />
      <TeacherChart v-if="page == $t('admin.admin_menu.menu15')" />
      <StudentChart v-if="page == $t('admin.admin_menu.menu16')" />
      <ClassesMBA v-if="page == 'CLassMBA'" />
      <MonthChart v-if="page == $t('admin.admin_menu.menu17')" />
      
      <TicketSupport v-if="page == $t('admin.admin_menu.menu31')" />
      <Serial v-if="page == ('Serial')" />

    </v-main>
  </v-app>
</template>
<script>
import Kits from "../components/Admin/Material/Kits.vue";
import Classes from "../components/Admin/Material/Classes.vue";
// import mercadopago from "../components/mercadopago.vue";
import Courses from "../components/Admin/Material/Courses.vue";
import Training from "../components/Admin/Material/Training/Training.vue";
import Schools from "../components/Admin/Users/Schools.vue";
import Teachers from "../components/Admin/Users/Teachers.vue";
import Student from "../components/Admin/Users/Students.vue";
// import Directors from "../components/Admin/Users/Directors.vue";
import Managers from "../components/Admin/Users/Managers.vue";
import Teams from "../components/Admin/Users/Teams.vue";
import Homework from "../components/Admin/Material/Homework/Homework.vue";
import Exercises from "../components/Admin/Material/Homework/Exercise.vue";
import Events from "../components/Admin/Material/Events.vue";
import Clients from "../components/Admin/Users/Clients.vue";
import UserChart from "../components/Admin/Reports/ReportUser.vue";
import TeacherChart from "../components/Admin/Reports/ReportTeacher.vue";
import StudentChart from "../components/Admin/Reports/ReportStudent.vue";
import MonthChart from "../components/Admin/Reports/MonthReport.vue";
import TicketSupport from "../components/Chat/TicketSupport.vue";
import ClassesPlan from "../components/Admin/Material/ClassesPlan";
import PlanoProfessor from "../components/Admin/Material/SuporteaoProfessor/SuporteProfessor";
import ConteudoAula from "../components/Admin/Material/SuporteaoProfessor/Conteudo";
import AcessoRapido from "../components/Admin/Material/AcessoRapido/AcessoRapido";
import ConteudoRP from "../components/Admin/Material/AcessoRapido/ConteudoRP";
import Serial from "../components/Admin/Material/Serial.vue"
import ClassesMBA from "../components/Admin/Material/ClassesMBA.vue";

export default {
  name: "Dashboard",
  data: () => ({
    drawer: null,
    logoutDialog: false,
    exerciseId: null,
    menu: [],
    page: "",
  }),
  components: {
    Kits,
    Classes,
    // mercadopago,
    Courses,
    Training,
    Schools,
    Teachers,
    Student,
    // Directors,
    Managers,
    Teams,
    Homework,
    Exercises,
    Events,
    Clients,
    UserChart,
    StudentChart,
    TeacherChart,
    MonthChart,
    TicketSupport,
    ClassesPlan,
    PlanoProfessor,
    ConteudoAula,
    AcessoRapido,
    ConteudoRP,
    Serial,
    ClassesMBA,
  },
  mounted() {
    document.title = this.$t('login.login_usuario.usuario6');
    this.menu = [
      {
        title: this.$t('teams.team_tab.tab4'),
        icon: "mdi-bookshelf",
        children: [
          this.$t('admin.admin_menu.menu1'),
          this.$t('admin.admin_menu.menu36'),
          this.$t('admin.admin_menu.menu2'),
          this.$t('admin.admin_menu.menu3'),
          'CLassMBA',
          this.$t('admin.admin_menu.menu33'),
          this.$t('admin.admin_menu.menu34'),
          this.$t('admin.admin_menu.menu4'),
          this.$t('admin.admin_menu.menu5'),
          this.$t('admin.admin_menu.menu7'),
          "Serial",
        ],
      },
      {
        title: this.$t('admin.admin_menu.menu28'),
        icon: "mdi-account",
        children: [
          this.$t('admin.admin_menu.menu8'),
          this.$t('admin.admin_menu.menu9'),
          this.$t('admin.admin_menu.menu12'),
          this.$t('admin.admin_menu.menu10'),
          this.$t('admin.admin_menu.menu13'),
          this.$t('admin.admin_menu.menu11'),
        ],
      },
      {
        title: this.$t('admin.admin_menu.menu29'),
        icon: "mdi-chart-bar",
        children: [
          this.$t('admin.admin_menu.menu17'),
          // "Acesso por Usuário",
          // "Acesso de Professores",
          // "Acesso de Alunos",
        ],
      },
      {
        title: this.$t('admin.admin_menu.menu31'),
        icon: "mdi-headset",
        children: [
          this.$t('admin.admin_menu.menu31'),
          // "Acesso por Usuário",
          // "Acesso de Professores",
          // "Acesso de Alunos",
        ],
      },
    ],
    this.page = this.$t('admin.admin_menu.menu8') 
  },
  methods: {
    setMessage(msg) {
      this.page = msg;
    },
    toLogin() {
      this.$router.go("/login/Admin");
    },
    logout() {
      this.logoutDialog = false;
      this.toLogin();
      localStorage.removeItem("accessToken");
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}

.logoutStyle {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
