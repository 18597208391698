<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import VueApexCharts from "vue-apexcharts";

export default {
  name: "App",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          position: "top",
          fontSize: "14px",
        },
        chart: {
          type: "bar",
          height: 350,
        },
        menu: {
          offsetY: 10,
          offsetX: 10,
          fontSize: "14px",
          width: 150,
        },
        colors: [
          "#FD80CD",
          "#1CFEE8",
          "#F9C228",
          "#197CFF",
          "#E245A6",
          "#089790",
          "#CF9C0E",
          "#0E4887",
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
        noData: {
          text: this.$t('alerta.alerta_numresultado'),
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "16px",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " acessos";
            },
          },
        },
      },
    };
  },
  created() {
    this.getAccess();
  },
  methods: {
    async getAccess() {
      let res = await axios.get(`${url}/access/count/student`, authorization);

      this.access = res.data.result[0].numberAccess;
      let response = [
        { month: "January", value: 32 },
        { month: "February", value: 67 },
        { month: "March", value: 12 },
        { month: "April", value: 81 },
        { month: "May", value: 75 },
        { month: "June", value: 58 },
        { month: "July", value: 97 },
        { month: "August", value: 91 },
        { month: "September", value: 11 },
        { month: "October", value: 3 },
        { month: "November", value: 89 },
        { month: "December", value: 19 },
      ];
      let months = [];
      response.forEach((element) => {
        months.push(element.month.substring(0, 3));
      });

      let values = [];
      response.forEach((element) => {
        values.push(element.value);
      });

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: months,
          },
        },
      };

      this.series = [
        { name: this.$t('admin.admin_menu.menu10'), data: values },
        { name: this.$t('teams.team_tab.tab3'), data: values },
        { name: this.$t('login.login_usuario.usuario4'), data: values },
      ];
    },
  },
};
</script>

<style></style>
