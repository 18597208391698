<template>
  <div class="backcolor ocult">
    <v-toolbar class="elevation-1 my-1">
      <v-icon color="pink" class="text-h4">mdi-view-dashboard</v-icon>
      <v-toolbar-title class="ml-3">
        <div class="d-flex flex-column">
          <span
            class="font-weight-bold"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'letter-spacing: 0.5rem'
                : 'letter-spacing: 0.2rem'
            "
          >
            {{ $t('admin.admin_dashbord') }}
          </span>
          <span class="font-weight-medium" style="font-size: 1rem">
            {{ formatedDate }}
          </span>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        ref="menu"
        v-model="dataPicker"
        :close-on-content-click="false"
        :return-value.sync="dateForFilter"
        transition="scroll-x-reverse-transition"
        offset-y
        left
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="mr-sm-2 mr-md-4">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="dateForFilter"
          @change="selectDate(dateForFilter)"
          type="month"
          no-title
          color="blue"
          :max="maxMonth"
          header-color="darkblue"
        >
        </v-date-picker>
      </v-menu>
    </v-toolbar>
    <v-row class="px-4 py-4 px-md-8 py-md-6 printing">
      <v-col cols="12" lg="8" order-md="1">
        <v-card class="rounded-lg" style="min-height: 45vh">
          <v-card-title
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
           {{ $t('school.school_numero') }}&nbsp; <b>{{ currentMonth }}</b>
          </v-card-title>
          <v-card-text>
            <MonthChart :chartData="chartData" ref="chartMonth" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4" order-md="3" order-lg="2">
        <v-card class="rounded-lg" style="height: 100%">
          <div class="grid-container pa-4">
            <div class="rounded-lg grid-item pink-bg">
              <v-icon size="40" class="mb-2" color="darkpink">
                mdi-account-group
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('school.school_acesso') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ totalAccess }}
              </span>
            </div>

            <div class="rounded-lg grid-item cyan-bg">
              <v-icon size="40" class="mb-2" color="darkcyan">
                mdi-bag-personal
              </v-icon>
              <span class="font-weight-medium"> {{ $t('admin.admin_menu.menu11') }} </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ accessNumbers.student }}
              </span>
            </div>
            <div class="rounded-lg grid-item green-bg">
              <v-icon size="40" class="mb-2" color="green">
                mdi-account-tie
              </v-icon>
              <span class="font-weight-medium"> {{ $t('admin.admin_menu.menu10') }} </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ accessNumbers.teacher }}
              </span>
            </div>
            <div class="rounded-lg grid-item yellow-bg">
              <v-icon size="40" class="mb-2" color="yellow">
                mdi-school
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('login.login_usuario.usuario4') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ accessNumbers.school }}
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="6" lg="4" order-md="3" order-lg="4">
        <v-card class="rounded-lg" style="height: 100%">
          <div class="grid-container pa-4">
            <div class="rounded-lg grid-item yellow-bg"></div>
            <div class="rounded-lg grid-item green-bg"></div>
            <div class="rounded-lg grid-item pink-bg"></div>
            <div class="rounded-lg grid-item cyan-bg"></div>
          </div>
        </v-card>
      </v-col> -->
      <v-col cols="12" lg="12" order-md="4" order-lg="3">
        <v-card class="rounded-lg" style="min-height: 45vh">
          <v-card-title
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
            {{ $t('admin.admin_acessorecentealuno') }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="recentData"
              hide-default-footer
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import MonthChart from "./Charts/MonthChart.vue";

export default {
  components: {
    MonthChart,
  },
  data: () => {
    return {
      maxMonth: new Date().toISOString().substr(0, 7),
      dateForFilter: "",
      dataPicker: false,
      modal: false,
      accessNumbers: [],
      totalAccess: 0,
      formatedDate: "",
      currentMonth: "",
      chartData: { name: [], data: [] },

      headers: [],
      recentData: [],
    };
  },
  created() {
    this.getDate(new Date());
  },
  mounted() {
    this.headers = [
      { text: this.$t('school.school_headers.headers1'), value: "name" },
      { text: this.$t('admin.admin_dataacesso'), value: "date" },
      { text: this.$t('admin.admin_horario'), value: "hour" },
      { text: this.$t('regisResponsavel.painel.escola'), value: "School" }
        // { text: "Cidade", value: "City" },
    ]
  },
  methods: {
    getDate(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      this.formateDate(date.getMonth(), year);

      this.selectDate(year + "-" + month, year);
    },
    selectDate(date) {
      this.dateForFilter = date;

      const [year, month] = date.split("-");
      let monthStr = parseInt(month).toString();
      this.formateDate(monthStr, year);
      this.recentAccess();
      this.getChartData();
      this.getAccess();
      setTimeout(() => {
        this.dataPicker = false;
      }, 100);
    },
    async recentAccess() {
      let body = { yearMonth: this.dateForFilter };
      let limit = 10;
      let res = await axios.post(
        `${url}/access/info/recent`,
        body,
        limit,
        authorization
      );
      this.recentData = res.data.result;
    },
    async getChartData() {
      let body = { yearMonth: this.dateForFilter };

      let res = await axios.post(
        `${url}/access/count/day`,
        body,
        authorization
      );

      this.chartData = { name: [], data: [] };

      for (let index = 0; index < res.data.result.length; index++) {
        const { days, count } = res.data.result[index];
        this.chartData.name.push(days);
        this.chartData.data.push(count);
      }

      this.$refs.chartMonth.updateData();
    },
    async getAccess() {
      let response = await axios.get(
        `${url}/access/count/month`,
        authorization
      );
      this.groupByDate(response.data.result);
    },
    async groupByDate(data) {
      let result = {};
      data.forEach((item) => {
        let date = item.month;
        if (!result[date]) {
          result[date] = {};
        }
        result[date][item.type] = item.count;
      });

      this.accessNumbers = result[this.dateForFilter];
      await this.sumValues(this.accessNumbers);
    },
    sumValues(accessNumbers) {
      let valuesArray = Object.values(accessNumbers);
      this.totalAccess = valuesArray.reduce((acc, val) => acc + val, 0);
    },
    formateDate(month, year) {
      let monthNames = [
        { full: this.$t('admin.admin_calendario.Janeiro'), abb: this.$t('admin.admin_calendarioabb.Janeiro') },
        { full: this.$t('admin.admin_calendario.Fevereiro'), abb: this.$t('admin.admin_calendarioabb.Fevereiro') },
        { full: this.$t('admin.admin_calendario.Março'), abb: this.$t('admin.admin_calendarioabb.Março') },
        { full: this.$t('admin.admin_calendario.Abril'), abb: this.$t('admin.admin_calendarioabb.Abril') },
        { full: this.$t('admin.admin_calendario.Maio'), abb: this.$t('admin.admin_calendarioabb.Maio') },
        { full: this.$t('admin.admin_calendario.Junho'), abb: this.$t('admin.admin_calendarioabb.Junho') },
        { full: this.$t('admin.admin_calendario.Julho'), abb: this.$t('admin.admin_calendarioabb.Julho') },
        { full: this.$t('admin.admin_calendario.Agosto'), abb: this.$t('admin.admin_calendarioabb.Agosto') },
        { full: this.$t('admin.admin_calendario.Setembro'), abb: this.$t('admin.admin_calendarioabb.Setembro') },
        { full: this.$t('admin.admin_calendario.Outubro'), abb: this.$t('admin.admin_calendarioabb.Outubro') },
        { full: this.$t('admin.admin_calendario.Novembro'), abb: this.$t('admin.admin_calendarioabb.Novembro') },
        { full: this.$t('admin.admin_calendario.Dezembro'), abb: this.$t('admin.admin_calendarioabb.Dezembro') },
      ];
      this.currentMonth = monthNames[month - 1].full;
      this.formatedDate = this.currentMonth + ", " + year;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 12px;
  height: 100%;
}

.pink-bg {
  background-color: rgba(253, 128, 205, 0.2);
}

.cyan-bg {
  background-color: rgba(28, 254, 232, 0.2);
}

.yellow-bg {
  background-color: rgba(249, 194, 40, 0.2);
}

.green-bg {
  background-color: rgba(25, 255, 56, 0.2);
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 16px 0;
}
</style>
