<template>
  <div class="chat-detail">
    <div class="chat-header">
      <span>Conversa com {{ ticket.userName }} ({{ ticket.role }})</span>
    </div>
    <div class="chat-body">
      <div class="messages">
        <div v-for="(message, index) in ticket.messages" :key="index" :class="['message', message.iduser == user.id ? 'user-message' : 'agent-message']">
          <strong>{{ message.user }}:</strong> {{ message.text }}
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <input
        type="text"
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Digite sua mensagem"
        :readonly="ticket.closedAt != null"
      />
      <button @click="sendMessage" :disabled="ticket.closedAt != null">Enviar</button>
      <button @click="closeSupport" :disabled="ticket.closedAt != null">Finalizar Atendimento</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: Object,
    user: Object,
  },
  data() {
    return {
      newMessage: '',
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        this.$emit('sendMessage', this.newMessage);
        this.newMessage = '';
      }
    },
    closeSupport() {
        this.$emit('closeSupport');
    }
  },
};
</script>

<style scoped>
.chat-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
}

.chat-body {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
}

.chat-footer {
  padding: 10px;
  display: flex;
  gap: 10px;
  border-top: 1px solid #ddd;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.messages {
  padding: 10px 0;
}

.message {
  margin-bottom: 8px;
  padding: 5px 10px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
}

.user-message {
  background-color: #e0e0e0;
  text-align: left;
  margin-left: 10px;
  align-self: flex-start;
}

.agent-message {
  background-color: #007bff;
  color: white;
  text-align: right;
  margin-right: 10px;
  align-self: flex-end;
}

.message strong {
  display: block;
}
</style>
