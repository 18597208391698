<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="red" class="text-h4">mdi-toy-brick</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >KITS
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="registerDialog"
        persistent
        :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
        :fullscreen="$vuetify.breakpoint.xsOnyl"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark color="darkpink" rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('botoes.botao_novo') }} {{ $t('courses.courses_template.template1') }}</span>
          </v-card-title>
          <v-card-text class="mt-5">
            <v-form lazy-validation ref="kitsForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.name"
                    color="blue"
                    :label="$t('label.label_comercial')"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.nickname"
                    color="blue"
                    :label="$t('admin.admin_apelido')"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="blue"
                    :label="$t('label.label_descricao')"
                    filled
                    :rules="[rules.required]"
                    rows="8"
                    v-model="form.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    show-size
                    color="blue"
                    truncate-length="20"
                    :label="editMode ? $t('homework.homework_image') : $t('label.label_imagem')"
                    accept="image/*"
                    v-model="thumb"
                    :rules="editMode ? [rules.none] : [rules.image]"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    show-size
                    color="blue"
                    truncate-length="20"
                    :label="$t('admin.admin_listapecas')"
                    accept=".pdf"
                    v-model="listPiece"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="mx-4 text-caption text--secondary">
              *{{ $t('regras.regra_obrigatorio') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="cancel()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" text rounded @click="saveBtn()">
              {{ $t('botoes.botao_enviar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="text-subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="text-subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row>
        <v-col v-for="kit in kitsQuery" :key="kit.id" cols="12" sm="6">
          <v-card height="100%">
            <v-card-title class="grey lighten-3" dark>
              <p class="pa-0 ma-0 text-caption">
                <span
                  class="
                    text-subtitle-1
                    font-weight-medium
                    text-uppercase
                    letter-spacing:
                    0.09rem
                  "
                  style="line-height: 0"
                >
                  {{ kit.name }}
                </span>
                <br />
                {{ kit.nickname }}
              </p>
              <v-spacer></v-spacer>

              <v-btn text small fab @click="editKit(kit)">
                <v-icon color="darkblue"> mdi-pencil </v-icon></v-btn
              >
              <v-dialog max-width="550px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small fab v-bind="attrs" v-on="on">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <v-card
                  class="rounded-lg py-3 error--text"
                  style="border-left: 12px inset red"
                >
                  <v-card-title>
                    <v-icon left large color="error">mdi-alert-circle</v-icon>
                    {{ $t('alerta.alerta_title') }}
                  </v-card-title>
                  <v-card-text class="black--text">
                    {{ $t('alerta.alerta_excluir.excluir1') }}
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end pr-4">
                    <v-btn
                      color="error"
                      dark
                      rounded
                      class="px-3"
                      @click="deleteKit(kit)"
                    >
                      {{ $t('botoes.botao_excluir') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 100%">
              <v-row align="center" style="height: 80%">
                <v-col cols="12" md="5" align="center" justify="center">
                  <v-img
                    :src="kit.thumbnail"
                    max-height="150"
                    cointain
                    loading
                    class="rounded-xl"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="7">
                  <span class="text-justify truncate">
                    {{ kit.description }}
                  </span>

                  <p class="text-center mt-3" v-if="!!kit.parts">
                    <v-btn
                      :href="kit.parts"
                      type="application/pdf"
                      target="_blank"
                      rounded
                      color="red darken-4"
                      text
                      small
                      dark
                    >
                      <v-icon color="red darken-1" style="font-size: 2rem">
                        mdi-file-pdf-box
                      </v-icon>
                      {{ $t('admin.admin_listapecas') }}
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      thumb: null,
      listPiece: null,
      registerDialog: false,
      deleteDialog: false,
      editMode: false,
      sendStatus: null,
      errorMessage: null,
      form: {},
      kitsQuery: [],
      errorSnackbar: false,
      successSnackbar: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
    };
  },
  methods: {
    registerFile(Image) {
      let formData = new FormData();
      let position = Image ? 0 : 1;
      formData.append("file", this.files[position], this.files[position].name);
      axios
        .post(`${url}/kits/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          Image
            ? (this.form.thumbnail = response)
            : (this.form.parts = response);
        })
        .catch((error) => {
          console.error(error);
          this.sendStatus = "error";
          this.errorMessage = error.response.error;
        });
      this.registerDialog = false;
    },
    async registerThumb() {
      let formData = new FormData();
      formData.append("file", this.thumb, this.thumb.name);
      await axios
        .post(`${url}/kits/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          let link = response.data.url;
          this.form.thumbnail = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async registerListPiece() {
      let formData = new FormData();
      formData.append("file", this.listPiece, this.listPiece.name);
      await axios
        .post(`${url}/kits/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          let link = response.data.url;
          this.form.parts = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.kitsForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerThumb();
            if (this.listPiece) {
              await this.registerListPiece();
            }
            await this.registerKit();

            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch (error) {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          if (this.thumb) {
            await this.registerThumb();
          }
          if (this.listPiece) {
            await this.registerListPiece();
          }
          this.updateKit();
        }
        this.registerDialog = false;
        this.editMode = false;
        this.$refs.kitsForm.reset();
      }
    },
    async registerKit() {
      await axios
        .post(`${url}/kits`, this.form, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getKits();
          setTimeout(() => {
            this.sendStatus = null;
          });
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
          this.registerDialog = false;
          this.errorSnackbar = true;
          this.errorMessage = error.response.error;
        });
    },
    cancel() {
      this.$refs.kitsForm.reset();
      this.registerDialog = false;
      this.editMode = false;
    },
    editKit(register) {
      this.form = Object.assign({}, register);
      this.registerDialog = true;
      this.editMode = true;
    },
    async updateKit() {
      await axios
        .put(`${url}/kits/${this.form.id}`, this.form, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getKits();
          setTimeout(() => {
            this.sendStatus = null;
          });
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
          this.sendStatus = "error";
          this.errorMessage = error.response.error;
        });
    },
    getKits() {
      axios
        .get(`${url}/kits`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          this.kitsQuery = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteKit(register) {
      let kitId = register.id;
      this.deleteDialog = false;
      axios
        .delete(`${url}/kits/${kitId}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getKits();
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
          this.sendStatus = "error";
          this.errorMessage = error.response.error;
          this.errorSnackbar = true;
        });
    },
  },
  mounted() {
    this.getKits();
  },
};
</script>

<style scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
