<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="orange" class="text-h4">mdi-robot</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('classes.classe_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="80%"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            color="darkpink"
            rounded
            v-bind="attrs"
            v-on="on"
            @click="clearAll()"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            {{ $t('classes.classe_new') }}
            <v-spacer></v-spacer>
            <v-btn icon text color="white" @click="cancelRegister()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-stepper v-model="registerData">
            <v-stepper-header class="elevation-0">
              <v-stepper-step
                color="darkblue"
                step="1"
                :complete="registerData > 1"
              >
                {{ $t('classes.classe_info') }}
              </v-stepper-step>
              <v-divider />

              <v-stepper-step
                color="darkblue"
                step="2"
                :complete="registerData > 2"
              >
                {{ $t('classes.classe_montagem.montagem1') }} <small>{{ $t('classes.classe_montagem.montagem2') }}</small>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="darkblue"
                step="3"
                :complete="registerData > 3"
              >
                {{ $t('classes.classe_montagem.montagem3') }}
                <small>{{ $t('classes.classe_montagem.montagem2') }}</small>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                color="darkblue"
                step="4"
                :complete="registerData > 4"
              >
                Adicionar Embed
                <small>{{ $t('classes.classe_montagem.montagem2') }}</small>
              </v-stepper-step>
            </v-stepper-header>
            <v-divider></v-divider>
            <v-form ref="classesForm">
              <v-stepper-content step="1">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        color="blue"
                        v-model="form.title"
                        :label="$t('courses.courses_template.template2')"
                        :rules="[getRules().required, getRules().Number, getRules().NumberLenght(2)]"
                        :hint="$t('descricao.descricao_aula')"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        color="blue"
                        item-color="darkblue"
                        :items="kitsQuery"
                        v-model="form.kitId"
                        :hint="$t('descricao.descricao_kit')"
                        persistent-hint
                        :label="$t('label.label_kit')"
                        item-value="id"
                        item-text="name"
                        :rules="[getRules().option]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" :sm="!editMode ? 6 : 12">
                      <v-file-input
                        color="blue"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-inner-icon="mdi-image-area"
                        prepend-icon=""
                        label="Thumbnail"
                        :rules="[getRules().none]"
                        :hint="$t('descricao.descricao_image')"
                        persistent-hint
                        v-model="thumb"
                      ></v-file-input>
                    </v-col>
                    <!-- <v-col cols="12" sm="6">
                      <v-slider
                        color="blue"
                        track-color="#197CFF50"
                        thumb-color="darkblue"
                        v-model="form.level"
                        min="1"
                        max="3"
                        label="Dificuldade"
                        :tick-labels="ticksLabels"
                        tick-size="4"
                        ticks
                      ></v-slider>
                    </v-col> -->
                    <v-col cols="12" sm="6" v-if="!editMode">
                      <v-autocomplete
                        color="blue"
                        item-color="darkblue"
                        :items="courseQuery"
                        v-model="form.course"
                        :hint="$t('descricao.descricao_curso')"
                        persistent-hint
                        :label="$t('label.label_curso')"
                        item-value="id"
                        item-text="name"
                        :rules="[getRules().option]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        color="blue"
                        clearable
                        :rules="[getRules().none]"
                        clear-icon="mdi-close-circle"
                        :label="$t('label.label_descricao')"
                        :hint="$t('descricao.descricao_resumo')"
                        persistent-hint
                        filled
                        no-resize
                        rows="5"
                        v-model="form.description"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      rounded
                      dark
                      color="darkpink"
                      class="px-6"
                      @click="stepTwo()"
                    >
                      {{ $t('botoes.botao_continuar') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        color="blue"
                        clearable
                        :rules="[getRules().none]"
                        clear-icon="mdi-close-circle"
                        label="Embed"
                        hint="Insira um embed para a aula."
                        persistent-hint
                        filled
                        no-resize
                        rows="5"
                        v-model="embed"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      rounded
                      dark
                      color="darkpink"
                      class="px-6"
                      @click="saveBtn()"
                    >
                      {{ $t('botoes.botao_salva') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card>
                  <v-col cols="12">
                    <v-file-input
                      v-model="presentation"
                      :label="$t('label.label_daAula')"
                      :hint="$t('teams.team_hint')"
                      persistent-hint
                      :rules="[getRules().none]"
                      accept="video/*"
                      color="blue"
                      prepend-inner-icon="mdi-video-box"
                      prepend-icon=""
                    >
                    </v-file-input>
                  </v-col>

                  <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                      rounded
                      text
                      color="darkpink"
                      class="px-6"
                      @click="registerData = 2"
                    >
                      {{ $t('botoes.botao_voltar') }}
                    </v-btn>
                    <v-btn
                      color="darkpink"
                      rounded
                      text
                      @click="registerData = 4"
                      :loading="loading"
                    >
                    {{ $t('botoes.botao_continuar') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        color="blue"
                        v-model="form.project"
                        :rules="[getRules().none]"
                        :label="$t('courses.courses_template.template3')"
                        :hint="$t('descricao.descricao_tema')"
                        persistent-hint
                        prepend-inner-icon="mdi-toy-brick"
                        prepend-icon=""
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        color="blue"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-inner-icon="mdi-led-on"
                        prepend-icon=""
                        :rules="[getRules().none]"
                        :label="$t('student.student_apresentacao.apresentacao2')"
                        :hint="$t('descricao.descricao_montagem')"
                        persistent-hint
                        v-model="mounting"
                        multiple
                        small-chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            active-class="blue--text text--accent-4"
                            close
                            @click:close="removeMount(data)"
                          >
                            {{ data.text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        color="darkblue"
                        v-model="isPortrait"
                        label="Retrato"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-card-actions class="d-flex justify-space-between">
                    <v-btn
                      rounded
                      text
                      color="darkpink"
                      class="px-6"
                      @click="registerData = 1"
                    >
                      {{ $t('botoes.botao_voltar') }}
                    </v-btn>
                    <v-btn
                      rounded
                      dark
                      color="darkpink"
                      class="px-6"
                      @click="registerData = 3"
                    >
                      {{ $t('botoes.botao_continuar') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-form>
          </v-stepper>
        </v-card>
        <!-- 

          <v-card-text>
            <v-container>
             
    

                  <v-col cols="12" sm="6">
                      <v-file-input
                        color="blue"
                        accept=".pdf"
                        prepend-icon="mdi-package"
                        label="Material Extra"
                        hint="Documentos necessários para aplicação da aula."
                        persistent-hint
                        v-model="extras"
                        multiple
                        small-chips
                        truncate-length="15"
                      >
                      </v-file-input>
                    </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

        </v-card> -->
      </v-dialog>
    </v-toolbar>

    <!-- TABLE FIELDS -->
    <v-card elevation="0" class="mt-5">
      <v-data-table
        :headers="headers"
        :items="dataQuery"
        :search="search"
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        sort-by="kitId"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-text-field
              color="blue"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('label.label_pesquisa')"
              single-line
              hide-details
              :style="
                $vuetify.breakpoint.smAndUp
                  ? 'max-width: 30%'
                  : 'max-width: 60%'
              "
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-dialog width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                outlined
                rounded
                color="darkblue"
                class="px-2 mx-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2" small> mdi-eye </v-icon>
                {{ $t('botoes.botao_detalhe') }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-subtitle-1 pr-2 text-uppercase">
                {{ item.title }}
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card
                    class="rounded-lg py-3 error--text"
                    style="border-left: 12px inset red"
                  >
                    <v-card-title>
                      <v-icon left large color="error">mdi-alert-circle</v-icon>
                      {{ $t('alerta.alerta_title') }}
                    </v-card-title>
                    <v-card-text class="black--text">
                      {{ $t('alerta.alerta_excluir.excluir1') }}
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end pr-4">
                      <v-btn
                        color="error"
                        dark
                        rounded
                        class="px-3"
                        @click="deleteItemConfirm(item)"
                      >
                        {{ $t('botoes.botao_excluir') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-btn text fab small @click="editClass(item)">
                  <v-icon color="darkblue">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text fab small @click="dialogDelete = true">
                  <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text class="mt-2">
                <v-row>
                  <v-col cols="6">
                    <v-avatar
                      :size="$vuetify.breakpoint.smAndUp ? 150 : 120"
                      tile
                      class="rounded-xl"
                    >
                      <v-img :src="item.tumbnail" alt="John" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="6">
                    <div>
                      <div><strong>{{ $t('classes.classe_projeto.projeto1') }} </strong> {{ item.project }}</div>

                      <!-- <div><strong>Dificuldade: </strong> {{ item.level }}</div> -->

                      <div>
                        <strong>{{ $t('classes.classe_projeto.projeto2') }} </strong>{{ idToTitle(item.kitId) }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-justify">
                    {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="darkblue">
                <v-row no-gutters>
                  <v-col cols="6" class="text-center">
                    <v-chip
                      class="px-4 px-sm-10"
                      color="darkpink"
                      dark
                      target="_blank"
                      @click="getVideo(item.id)"
                    >
                      <v-avatar left>
                        <v-icon>mdi-book-open-variant</v-icon>
                      </v-avatar>
                      {{ $t('classes.classe_projeto.projeto3') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="6" class="text-center">
                    <v-chip
                      class="px-4 px-sm-10"
                      color="darkpink"
                      dark
                      type="application/pdf"
                      @click="getFiles(item.id)"
                    >
                      <v-avatar left>
                        <v-icon>mdi-toy-brick</v-icon>
                      </v-avatar>
                      {{ $t('botoes.botao_montagem') }}
                    </v-chip>
                  </v-col>
                  <!-- <v-col cols="4" class="text-center">
                    <v-chip
                      color="darkpink"
                      dark
                      rounded
                      :href="extraFile"
                      target="”_blank”"
                    >
                      <v-icon left v-if="!mobile"> mdi-folder-open </v-icon>
                      Arquivos
                    </v-chip>
                  </v-col> -->
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:no-data> {{ $t('classes.classe_dado') }} </template>
        <template v-slot:[`item.kitId`]="{ item }">
          {{ idToTitle(item.kitId) }}
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="d_carousel"
      :max-width="$vuetify.breakpoint.smAndUp ? '70%' : '95%'"
    >
      <v-card
        flat
        class="d-flex justify-center align-center mx-auto pa-8 rounded-lg"
      >
        <v-carousel
          hide-delimiters
          show-arrows-on-hover
          :continuous="false"
          v-model="m_corousel"
          :height="isShowPortrait ? 1200 : 500"
        >
          <v-carousel-item
            v-for="mount in mounting"
            :key="mount.id"
            :src="mount.uri"
            contain
          >
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="d-flex justify-center align-center">
            <v-carousel hide-delimiter-background :continuous="false" :cycle="false">
              <v-carousel-item v-for="(video, index) in videoData" :key="index">
                <vimeo-player style="height: 450px;" class="pa-4 d-flex align-center justify-center" :video-id="video.uri"></vimeo-player>
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.6.11/dist/vuetify.min.js"></script>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
//const storage = process.env.VUE_APP_STORAGE_URL;
const token = "Baerer " + localStorage.getItem("accessToken");


export default {  
  data: () => ({
    embed: "",
    mobile: false,
    kitsQuery: [],
    courseQuery: [],
    dataQuery: [],
    videoData: [],
    editMode: false,
    videoDialog: false,
    playerReady: false,
    sendStatus: null,
    form: {},
    ticksLabels: [],
    files: [],
    registerData: 1,
    page: 1,
    pageCount: null,
    itemsPerPage: 25,
    search: "",
    d_carousel: false,
    m_corousel: 0,
    headers: [],
    /*rules: {
      email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
      Number: (v) => !!(v || "").match(/^[0-9]+$/) || this.$t('regras.regra_aula'),
      length: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
      NumberLenght: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_digito.digito1')} ${len} ${this.$t('regras.regra_digito.digito2')}`,
      required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      option: (v) => !!v || this.$t('regras.regra_opcao'),
      image: (v) => !!v || this.$t('regras.regra_image'),
      question: (v) => !!v || this.$t('regras.regra_question'),
      file: (v) => !!v || this.$t('regras.regra_arquivo'),
      date: (v) => !!v || this.$t('regras.regra_data'),
      none: true,
    },*/
    loading: false,
    dialog: false,
    dialogDelete: false,
    successSnackbar: false,
    errorSnackbar: false,
    presentation: [],
    mounting: [],
    extras: [],
    thumb: null,
    mount: null,
    idCreatedClass: null,
    presentationFile: [],
    extraFile: null,
    isPortrait: false,
    isShowPortrait: false,
  }),

  computed: {
    formTitle() {
      return this.editMode === false ? this.$t('classes.classe_new') : this.$t('botoes.botao_editaAula');
    },
  },

  watch: {},

  created() {
    this.getOptions();
    this.getData();
  },
  
  mounted() {
    this.mobile = this.$vuetify.breakpoint.smAndDown;
    this.headers = [
      {
        text: "Kit",
        align: "start",
        value: "Kit.name",
      },
      { text: this.$t('school.school_headers.heardes7'), value: "title" },
      { text: this.$t('courses.courses_template.template3'), value: "project" },
      { text: "", value: "details", sortable: false, align: "end" },
    ],

    this.ticksLabels = [this.$t('classes.classe_ticklabel.tick1'), this.$t('classes.classe_ticklabel.tick2'), this.$t('classes.classe_ticklabel.tick3')]
  },
  methods: {
    getRules() {
      return {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        Number: (v) => !!(v || "").match(/^[0-9]+$/) || this.$t('regras.regra_aula'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        NumberLenght: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_digito.digito1')} ${len} ${this.$t('regras.regra_digito.digito2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        file: (v) => !!v || this.$t('regras.regra_arquivo'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      };
    },
    cancelRegister() {
      this.$refs.classesForm.reset();
      this.dialog = false;
      this.editMode = false;
      this.registerData = 1;
    },
    stepTwo() {
      let validation = this.$refs.classesForm.validate();

      if (validation) {
        this.registerData = 2;
      }
    },
    async saveBtn() {
      this.loading = true;
      this.form.title = this.$t('admin.admin_aula')  + this.form.title
      if (!this.editMode) {
        try {
          await this.registerThumb();

          let lesson = await this.registerClasses();

          await this.registerPresentations(lesson);
          await this.courseAssembly(lesson);

          if (this.mounting.length !== 0) {
            await this.registerMounting(lesson);
          }

          else if(this.embed !== '') {
            await this.embedRegister(lesson)
          }

          this.registerData = 1;
          this.loading = false;
          this.successSnackbar = true;
        } catch {
          this.errorSnackbar = true;
        }
      }
      if (this.editMode) {
        try {
          if (this.thumb) {
            await this.registerThumb();
          }
          if (this.presentation) {
            await this.registerPresentations(this.form.id);
          }

          if (this.mounting.length !== 0) {
            await this.registerMounting(this.form.id);
          }

          else if(this.embed !== '') {
            await this.embedUpgrade(this.form.id)
          }

          await this.updateClasses();
          this.loading = false;
        } catch {
          this.errorSnackbar = true;
        }
      }
      this.dialog = false;
      this.registerData = 1;
      this.editMode = false;
      this.isPortrait = false;
      this.mounting = [];
      this.loading = false;
      this.getData();
    },
    editClass(register) {
      //this.$refs.classesForm.reset();
      this.mounting = [];
      this.form = Object.assign({}, register);
      this.form.title = this.form.title.split(this.$t('admin.admin_aula') ).join('') 
      this.dialog = true;
      this.editMode = true;
    },
    cancel() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.classesForm.reset();
      this.registerData = 1;
    },
    async embedRegister(lessonId) {
      let data = {
        classId: lessonId,
        embed: this.embed,
      };
      await axios.post(`${url}/embed`, data, {
        headers: {
          authorization: token,
        },
      });
    },
    async embedUpgrade(lessonId) {
      let exerc = await axios.get(
        `${url}/embed/${lessonId}`,
        {
          headers: {
              authorization: token,
           },
         }
       );
      if (exerc.data.length === 0) {
        this.embedRegister(lessonId)
      
      } else {
        let data = {
        embed: this.embed
        }
        await axios.put(`${url}/embed/${lessonId}`, data, {
        headers: {
          authorization: token,
        },
      });
      }
    },
    async courseAssembly(lessonId) {
      let courses = await axios.get(
        `${url}/courseAssembly/testando/${this.form.course}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      let order = courses.data.length;
      let data = {
        fk_courseId: this.form.course,
        fk_classId: lessonId,
        order: order,
      };
      await axios.post(`${url}/courseAssembly`, data, {
        headers: {
          authorization: token,
        },
      });
    },
    async getOptions() {
      await axios
        .get(`${url}/basics/kits`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.kitsQuery = res.data;
        })
        .catch((err) => {
          console.error(err);
        });

      await axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courseQuery = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getData() {
      axios
        .get(`${url}/classes`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.dataQuery = res.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getFiles(id) {
      let m = await axios.get(`${url}/lessonsMountings/byClass/${id}`);

      if (m.data.length !== 0) {
        this.m_corousel = 0;
        this.mounting = m.data;
        this.d_carousel = true;
        this.isShowPortrait = m.data[0].isPortrait;
      }
    },
    async getVideo(id) {
      this.videoData = [];
      let m = await axios.get(`${url}/lessonsPresentations/byClass/${id}`);
      if (m.data.length !== 0) {
        this.videoData = m.data;
        this.videoDialog = true
      }
    },
    onReady() {
      this.playerReady = true;
    },
    async registerThumb() {
      let formData = new FormData();
      formData.append("file", this.thumb, this.thumb.name);
      await axios
        .post(`${url}/classes/files`, formData, {})
        .then((response) => {
          let link = response.data.url;
          this.form.tumbnail = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerPresentations(lessonId) {
      try {
        let formData = new FormData();
        formData.append("video", this.presentation, this.presentation.name);
        axios
          .post(`${url}/upload-video/vimeo`, formData)
          .then((response) => {
            let link = response.data.videoId;
            let presentation = {
              classId: lessonId,
              uri: link,
            };
            axios
              .post(`${url}/lessonsPresentations`, presentation, {})
              .then(() => {})
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    },

    async registerMounting(lessonId) {
      let index = 1;
      if (this.editMode) {
        await axios.delete(`${url}/lessonsMountings/${lessonId}`);
      }
      for (const iterator of this.mounting) {
        try {
          let formData = new FormData();
          formData.append("file", iterator, iterator.name);
          await axios
            .post(`${url}/lessonsMountings/files`, formData)
            .then((response) => {
              let link = response.data.url;
              let mounting = {
                classId: lessonId,
                uri: link,
                order: index,
                isPortrait: this.isPortrait,
              };
              axios.post(`${url}/lessonsMountings`, mounting).catch((error) => {
                console.error(error);
              });
              index++;
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {
          console.error(error);
        }
      }
    },
    clearAll() {
      if (this.$refs.classesForm) {
        this.$refs.classesForm.reset();
      }
      this.editMode = false;
      this.loading = false;
      this.isPortrait = false;
      this.mounting = [];
    },
    removeMount(item) {
      if (item.index >= 0) this.mounting.splice(item.index, 1);
    },
    remove(item) {
      if (item.index >= 0) this.presentation.splice(item.index, 1);
    },
    async registerClasses() {
      console.log(this.form)
      try {
        let response = await axios.post(`${url}/classes`, this.form, {
          headers: {
            authorization: token,
          },
        });
        let lessons = response.data.success.id;

        return lessons;
      } catch (error) {
        console.error(error);
        this.errorSnackbar = true;
      }
    },

    //##Changes kit's name to id
    // let found = this.kitsQuery.find(
    //   (element) => element.name == this.form.kitId
    // );
    // this.form.kitId = found.id;
    // // Object.values(this.kitsQuery).forEach((element) => {
    // //   if (element.name == this.form.kitId) {
    // //     this.form.kitId = element.id;
    // //   }
    // // });
    // // let formData = new FormData();
    // // Object.entries(this.form).forEach((element) => {
    // //   formData.append(element[0], element[1]);
    // // });
    // // Object.values(this.files).forEach((element) => {
    // //   formData.append("core", element, element.name);
    // // });
    // // Object.values(this.extras).forEach((element) => {
    // //   formData.append("extras", element, element.name);
    // // });
    // axios
    //   .post(`${url}/classes`, this.form)
    //   .then((response) => {

    //     this.clearFields();
    //     setTimeout(() => {
    //       this.registerDialog = false;
    //       this.sendStatus = null;
    //     }, 3000);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     this.sendStatus = "error";
    //     this.errorMessage = error.response.error;
    //   });

    clearFields() {
      this.getOptions();
      this.getData();
    },
    idToTitle(itemId) {
      let found = "";
      found = this.kitsQuery.find((element) => element.id == itemId);
      return found.name;
    },
    formatDate(string) {
      let formated = new Date(string);
      let date = formated.toLocaleDateString("en-GB");
      let time = formated.toLocaleTimeString("en-GB");
      return date.concat(" ", time);
    },

    //##Old##
    async updateClasses() {
      console.log(this.form)
      await axios
        .put(`${url}/classes/${this.form.id}`, this.form, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
          this.getData();
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
    },

    deleteItemConfirm(item) {
      let classId = item.id;

      axios
        .delete(`${url}/classes/${classId}`, {
          headers: {
            authorization: token,
          },
          data: this.form,
        })
        .then(() => {
          this.getOptions();
          this.getData();
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
      this.dialogDelete = false;
    },
  },
};
</script>

  <style></style>