<template>
  <div>
    <v-row class="my-4 mx-6">
      <v-col cols="12" sm="6" md="4" lg="3" class="px-6">
        <v-menu
          v-model="startDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startingDate"
              :label="$t('certificado.cert_simples.conteudo4')"
              prepend-icon="mdi-calendar"
              color="darkblue"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="darkblue"
            header-color="darkblue"
            v-model="startingDate"
            @input="startDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" class="px-6">
        <v-menu
          v-model="endDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          color="darkblue"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endingDate"
              :label="$t('certificado.cert_simples.conteudo5')"
              prepend-icon="mdi-calendar"
              color="darkblue"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="darkblue"
            header-color="darkblue"
            v-model="endingDate"
            @input="endDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" class="px-6">
        <v-autocomplete
          v-model="sortBy"
          :items="sortItems"
          prepend-icon="mdi-sort-variant"
          :label="$t('admin.admin_filtrar')"
          color="darkblue"
          item-color="darkblue"
          auto-select-first
          @change="getOtherData()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" class="px-6">
        <v-autocomplete
          v-model="divideBy"
          :items="divItems"
          prepend-icon="mdi-filter-variant"
          :label="divLabel"
          color="darkblue"
          item-color="darkblue"
          auto-select-first
          :disabled="sortBy == 'all'"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="d-felx justify-center align-center mt-16">
      <v-col cols="11" md="10" lg="8">
        <UserChart />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import UserChart from "./Charts/UserChart.vue";

export default {
  components: {
    UserChart,
  },
  data: () => {
    return {
      startingDate: null,
      endingDate: null,
      menu: false,
      modal: false,
      startDate: false,
      endDate: false,
      sortBy: "all",
      divideBy: "all",
      divItems: [],
      divLabel: '',
      sortItems: [
        { text: this.$t('events.events_headers.headers1'), value: "all" },
        { text: this.$t('admin.admin_sort.sort1'), value: "state" },
        { text: this.$t('admin.admin_sort.sort2'), value: "city" },
        { text: this.$t('admin.admin_menu.menu9'), value: "school" },
      ],
    };
  },
  
  mounted() {
    this.divLabel = this.$t('admin.admin_selecionefiltra')
  },

  methods: {
    async getOtherData() {
      if (this.sortBy !== "all") {
        // School's Info
        let res = await axios.get(`${url}/schools`, authorization);

        // Variables
        let schools = [];
        let cities = [];
        let states = [];

        // Making Arrays
        res.data.forEach((element) => {
          schools.push({ text: element.name, value: element.id });
          cities.push(element.city);
          states.push(element.state);
        });

        // Removing Test Schools
        this.removeTestSchools(schools);

        // Setting 'divBy' Array
        if (this.sortBy == "school") {
          let schs = [...new Set(schools)];
          this.divItems = schs;
          this.divLabel = this.$t('admin.admin_selecione.selecione1');
        }
        if (this.sortBy == "city") {
          let cits = [...new Set(cities)];
          this.divItems = cits;
          this.divLabel = this.$t('admin.admin_selecione.selecione2');
        }
        if (this.sortBy == "state") {
          let stas = [...new Set(states)];
          this.divItems = stas;
          this.divLabel = this.$t('admin.admin_selecione.selecione3');
        }
      } else {
        // Reseting Array
        this.divItems = [];
        this.divLabel = this.$t('admin.admin_selecionefiltra');
      }
    },

    removeTestSchools(data) {
      // Setting Testing Schools
      let removeSchool = [15, 53, 54, 61];

      removeSchool.forEach((elem) => {
        // Finding Schools
        let indexToRemove = data.findIndex((element) => element.value === elem);
        // Removing Schools
        if (indexToRemove !== -1) {
          data.splice(indexToRemove, 1);
        }
      });

      return data;
    },
  },
};
</script>

<style></style>
