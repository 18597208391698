<template>
  <div>
    <v-toolbar v-if="page === 'folders'" dense class="elevation-1">
      <v-icon color="purple" class="text-h4">mdi-human-male-board</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('treinamento.treinamento_title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-toolbar v-if="page === 'files'" class="darkblue">
      <v-btn dark rounded color="darkpink" @click="backFolder()">
        <v-icon left>mdi-arrow-left</v-icon> Voltar</v-btn
      >
    </v-toolbar>
    <TrainingFolders
      v-if="page === 'folders'"
      @enterFiles="updatePage"
    ></TrainingFolders>
    <TrainingFiles v-if="page === 'files'" :folderId="folderId"></TrainingFiles>
  </div>
</template>

<script>
import TrainingFolders from "./TrainingFolders.vue";
import TrainingFiles from "./TrainingFiles.vue";
export default {
  components: {
    TrainingFolders,
    TrainingFiles,
  },
  data() {
    return {
      page: "folders",
      folderId: null,
    };
  },
  methods: {
    backFolder() {
      this.page = "folders";
    },
    updatePage(variable) {
      this.page = variable.path;
      this.folderId = variable.folderId;
    },
    addNewFolder() {
      let i = this.formFolder.files.length;

      this.testFolders.push({
        title: this.formFolder.name,
        subtitle: i + " arquivos",
      });
      this.newFolderDialog = false;
    },
  },
};
</script>

<style lang="css" scoped></style>
