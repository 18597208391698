<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="yellow" class="text-h4">mdi-folder-open</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('courses.courses_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="registerDialog"
        persistent
        max-width="60%"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark color="darkpink" rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">{{ $t('botoes.botao_novocurso') }}</v-card-title>
          <v-card-text class="mt-5">
            <v-form ref="coursesForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    color="blue"
                    :label="$t('label.label_comercial')"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="blue"
                    :label="$t('label.label_descricao')"
                    filled
                    rows="8"
                    :rules="[rules.required]"
                    v-model="form.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="5">
                  <v-file-input
                    show-size
                    color="blue"
                    truncate-length="20"
                    :label="editMode ? $t('homework.homework_image') : $t('label.label_imagem')"
                    accept="image/*"
                    :rules="editMode ? [rules.none] : [rules.image]"
                    v-model="thumb"
                  ></v-file-input>
                </v-col>
                <v-col cols="4">
                  <v-select
                    show-size
                    color="blue"
                    truncate-length="20"
                    :label="editMode ? $t('label.label_categoria.categoria_title') : $t('label.label_categoria.categoria_title')"
                    :rules="[rules.none]"
                    :items="categoryForCourse"
                    item-text="name"
                    item-value="id"
                    v-model="form.categoryCoursesId"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    color="darkblue"
                    v-model="form.serial"
                    label="Serial"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="cancel()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>

            <v-btn color="darkpink" text rounded @click="saveBtn()">
              {{ $t('botoes.botao_enviar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-autocomplete
          class="mt-4"
          color="blue"
          v-model="categorySelect"
          :items="categories"
          :label="$t('courses.courses_categoria.categorias')"
          item-text="name"
          item-value="id"
          @change="getSelectedCategory()"
        ></v-autocomplete>
        <div class="d-flex align-center ml-8" >
          <v-btn
            class="mx-2"
            dark
            color="success"
            rounded
            @click="openCategoryRegisterDialog()"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_criar') }}
          </v-btn>
          <v-btn
            class="mx-2"
            dark
            color="error"
            rounded
            @click="categoryDeleteDialog = true"
          >
            <v-icon left> mdi-delete </v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </div>
      </v-row>
      <v-row>
        <v-col v-for="course in coursesQuery" :key="course.id" cols="12" sm="6">
          <v-card height="100%">
            <v-card-title class="grey lighten-4" dark>
              <p class="pa-0 ma-0 text-caption">
                <span
                  :style="
                    $vuetify.breakpoint.mdAndUp
                      ? 'max-width: 250px;'
                      : 'max-width: 150px;'
                  "
                  class="
                    text-subtitle-1
                    font-weight-medium
                    text-uppercase
                    d-inline-block
                    text-truncate
                  "
                  >{{ course.name }}</span
                >
              </p>
              <v-spacer></v-spacer>

              <!-- TABLE FIELDS -->
              <v-speed-dial
                right
                direction="left"
                transition="slide-x-reverse-transition"
              >
                <template v-slot:activator>
                  <v-btn small text dark fab class="mr-n4">
                    <v-icon color="blue darken-2"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  dark
                  color="darkyellow"
                  small
                  fab
                  @click="openFileDialog(course)"
                >
                  <v-icon> mdi-file-download </v-icon>
                </v-btn>
                <v-btn
                  dark
                  color="darkpink"
                  small
                  fab
                  @click="openAddDialog(course)"
                >
                  <v-icon> mdi-book-plus </v-icon>
                </v-btn>
                <v-btn
                  dark
                  color="darkblue"
                  small
                  fab
                  @click="editCourse(course)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <v-dialog max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      small
                      color="error"
                      fab
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card
                    class="rounded-lg py-3 error--text"
                    style="border-left: 12px inset red"
                  >
                    <v-card-title>
                      <v-icon dark large color="error">mdi-alert-circle</v-icon>
                      {{ $t('alerta.alerta_title') }}
                    </v-card-title>
                    <v-card-text class="black--text">
                      {{ $t('alerta.alerta_excluir.excluir1') }}
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end pr-4">
                      <v-btn
                        color="error"
                        dark
                        rounded
                        class="px-3"
                        @click="deleteCourse(course)"
                      >
                      {{ $t('botoes.botao_excluir') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-speed-dial>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 100%">
              <v-row align="center" style="height: 80%">
                <v-col cols="5" sm="12" md="5" align="center" justify="center">
                  <v-img
                    :src="course.thumbnail"
                    height="150"
                    :max-width="$vuetify.breakpoint.sm ? '200px' : ''"
                    cointain
                    loading
                    class="rounded-xl"
                  ></v-img>
                </v-col>
                <v-col>
                  <p class="text-justify truncate text-body-2">
                    {{ course.description }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template>
      <v-dialog
        v-model="assemblyDialog"
        transition="dialog-bottom-transition"
        :width="$vuetify.breakpoint.mdAndUp ? '85%' : '90%'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
      >
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            {{ $t('courses.courses_vincula') }}
            <v-spacer> </v-spacer>
            <v-btn icon dark @click="closeLink()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-4">
            <div class="text-subtitle-1 black--text font-weight-bold">
              {{ $t('courses.courses_seleciona') }}
            </div>
            <v-form ref="linkForm">
              <v-row>
                <v-col cols="6" sm="2">
                  <v-select
                    color="blue"
                    v-model="kitId"
                    :items="kits"
                    item-text="name"
                    item-value="id"
                    :label="$t('admin.admin_menu.menu1')"
                    @change="getClassByKits()"
                    :rules="[rules.none, rules.option]"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select
                    color="blue"
                    v-model="classId"
                    :items="classes"
                    item-text="title"
                    item-value="id"
                    :label="$t('label.label_aulas')"
                    :rules="[rules.option]"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="2">
                  <v-text-field
                    color="blue"
                    :label="$t('label.label_ordem')"
                    v-model="ordemAula"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    color="darkpink"
                    @click="registerCourseAssembly()"
                    :loading="loadingSend"
                  >
                    {{ $t('botoes.botao_enviar') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-simple-table
              fixed-header
              :height="$vuetify.breakpoint.mdAndUp ? 400 : 300"
              class="px-4"
            >
              <template v-slot:default>
                <v-row no-gutters class="font-weight-bold" align-self="center">
                  <v-col cols="3" class="text-left"> {{ $t('courses.courses_template.template1') }} </v-col>
                  <v-col cols="3" class="text-left"> {{ $t('courses.courses_template.template2') }} </v-col>
                  <v-col cols="5" class="text-left"> {{ $t('courses.courses_template.template3') }}</v-col>
                </v-row>
                <drop-list
                  class="mt-2"
                  :items="courseAssembly"
                  @reorder="$event.apply(courseAssembly)"
                  @insert="insert1"
                  mode="cut"
                >
                  <template v-slot:item="{ item, reorder }">
                    <drag
                      :key="item.id"
                      :data="item"
                      @cut="remove(courseAssembly, item)"
                    >
                      <v-row
                        align-self="center"
                        no-gutters
                        :style="
                          reorder
                            ? {
                                borderLeft: '2px solid #1976D2',
                                marginLeft: '-2px',
                              }
                            : {}
                        "
                      >
                        <v-col cols="3" align-self="center">
                          {{ item.Class.Kit.name }}
                        </v-col>
                        <v-col cols="3" align-self="center">
                          {{ item.Class.title }}
                        </v-col>
                        <v-col cols="5" align-self="center">
                          {{ item.Class.project }}
                        </v-col>
                        <v-col
                          cols="1"
                          align-items="end"
                          class="d-flex justify-end"
                        >
                          <v-btn
                            color="error"
                            icon
                            class="ml-auto mr-2"
                            @click="deleteAssemblyCourse(item)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </drag>
                  </template>
                </drop-list>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-4 pt-2 pb-3">
            <v-btn
              color="darkpink"
              rounded
              dark
              class="px-8"
              @click="saveAssembly()"
              :loading="loading"
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>

          <v-dialog v-model="dialogCourseAssemblyDelete" max-width="500px">
            <v-card
              class="rounded-lg py-3 error--text"
              style="border-left: 12px inset red"
            >
              <v-card-title>
                <v-icon left large color="error">mdi-alert-circle</v-icon>
                {{ $t('alerta.alerta_title') }}
              </v-card-title>
              <v-card-text class="black--text">
                {{ $t('alerta.alerta_excluir.excluir1') }}
              </v-card-text>
              <v-card-actions class="d-flex justify-end pr-4">
                <v-btn
                  color="error"
                  dark
                  rounded
                  class="px-3"
                  @click="courseAssemblyDelete()"
                >
                  {{ $t('botoes.botao_excluir') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-dialog>
    </template>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="categoryRegisterDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="
            darkblue
            white--text
            font-weight-bold
            d-flex
            justify-space-between
          "
          style="font-size: 1.6rem"
        >
          <span>{{ $t('courses.courses_categoria.registrar') }} </span>
          <v-btn icon dark @click="closeCategoryRegisterDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pt-2 pb-6 px-6">
          <v-row :no-gutters="!$vuetify.breakpoint.smAndUp">
            <v-col cols="12" sm="10">
              <v-text-field
                color="blue"
                label="Nome da categoria"
                v-model="categoryNameRegister"
              />
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="2" sm="2">
              <v-btn
                color="darkpink"
                rounded
                dark
                :block="!$vuetify.breakpoint.smAndUp"
                @click="registerCategoryCourses()"
              >
                {{ $t('botoes.botao_enviar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="categoryDeleteDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="
            darkblue
            white--text
            font-weight-bold
            d-flex
            justify-space-between
          "
          style="font-size: 1.6rem"
        >
          <span>{{ $t('courses.courses_categoria.deletar') }} </span>
          <v-btn icon dark @click="categoryDeleteDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pt-2 pb-6 px-6">
          <v-row :no-gutters="!$vuetify.breakpoint.smAndUp">
            <v-col cols="12" sm="10">
              <v-autocomplete
                class="mt-4"
                color="blue"
                v-model="categoryDeleteSelect"
                :items="categories"
                label="categorias"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="2" sm="2">
              <v-btn
                color="darkpink"
                rounded
                dark
                :block="!$vuetify.breakpoint.smAndUp"
                @click="deleteCategoryCourses()"
              >
                {{ $t('botoes.botao_enviar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fileDialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="
            darkblue
            white--text
            font-weight-bold
            d-flex
            justify-space-between
          "
          style="font-size: 1.6rem"
        >
          <span>{{ $t('courses.courses_arquivo') }} </span>
          <v-btn icon dark @click="fileDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="pt-2 pb-6 px-6">
          <v-form ref="fileForm">
            <v-row :no-gutters="!$vuetify.breakpoint.smAndUp">
              <v-col cols="12" sm="5">
                <v-text-field
                  color="blue"
                  :label="$t('label.label_arquivos.descricao')"
                  :rules="[rules.required]"
                  v-model="formFile.description"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  color="blue"
                  prepend-inner-icon="mdi-link"
                  show-size
                  :label="$t('label.label_arquivos.link')"
                  :rules="[rules.required]"
                  v-model="formFile.uri"
                />
              </v-col>
              <v-col class="d-flex justify-end align-center" cols="12" sm="2">
                <v-btn
                  color="darkpink"
                  rounded
                  dark
                  :block="!$vuetify.breakpoint.smAndUp"
                  @click="registerFile()"
                >
                  {{ $t('botoes.botao_enviar') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="mt-6">
            <v-col
              cols="12"
              sm="6"
              v-for="(file, index) in files"
              :key="file.id"
            >
              <v-card
                :href="file.uri"
                target="_blank"
                class="pa-0 flat white--text"
                height="100"
              >
                <v-alert
                  height="100%"
                  class="
                    d-flex
                    align-center
                    justify-center
                    font-weight-medium
                    text-center
                  "
                  style="font-size: 1.3rem"
                  :color="colorLookLight(index)"
                  text
                  outlined
                >
                  {{ file.description }}
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

import { Drag, DropList } from "vue-easy-dnd";

export default {
  components: {
    Drag,
    DropList,
  },
  data() {
    return {
      fileDialog: false,
      loading: false,
      loadingSend: false,
      errorSnackbar: false,
      successSnackbar: false,
      thumb: null,
      registerDialog: false,
      deleteDialog: false,
      editMode: false,
      form: {},
      coursesQuery: [],
      classes: [],
      courseAssembly: [],
      assemblyDialog: false,
      widgets: false,
      ordemAula: null,
      classId: null,
      fab: false,
      files: {},
      headers: [
        { text: this.$t('courses.courses_headers.headers1'), value: "order", align: "start" },
        { text: this.$t('courses.courses_headers.headers2'), value: "Class.Kit.name" },
        { text: this.$t('courses.courses_headers.headers3'), value: "Class.title" },
        { text: this.$t('courses.courses_headers.headers4'), value: "Class.project" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      dialogCourseAssemblyDelete: false,
      assemblySelectDelete: [],
      kits: [],
      kitId: null,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      classColors: [
        { light: "cyan", dark: "darkcyan" },
        { light: "pink ", dark: "darkpink" },
        { light: "yellow", dark: "darkyellow" },
      ],
      formFile: {},
      categorySelect: null,
      categories: [{ id: 0, name: this.$t('courses.courses_categoria.allcategoria') }],
      categoryForCourse: {},
      categoryRegisterDialog: false,
      categoryNameRegister: null,
      categoryDeleteDialog: false,
      categoryDeleteSelect: null,
    };
  },
  created() {
    this.getCourses();
    this.getClasses();
    this.getKits();
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        let res = await axios.get(`${url}/categoryCourses`, {
          headers: {
            authorization: token,
          },
        });
        //console.log(res.data);
        this.categories = [{ id: 0, name: this.$t('courses.courses_categoria.allcategoria') }, ...res.data];
        this.categoryForCourse = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async registerCategoryCourses() {
      try {
        let body = {
          name: this.categoryNameRegister,
        };
        await axios.post(`${url}/categoryCourses`, body, {
          headers: {
            authorization: token,
          },
        });
        this.getCategories(this.form.id);
        this.closeCategoryRegisterDialog();
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCategoryCourses() {
      try {
        await axios.delete(
          `${url}/categoryCourses/${this.categoryDeleteSelect}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        this.getCategories(this.form.id);
        this.closeCategoryRegisterDialog();
      } catch (error) {
        console.error(error);
      }
    },
    closeCategoryRegisterDialog() {
      this.categoryRegisterDialog = false;
      this.categorySelect = null;
    },
    getSelectedCategory() {
      if (this.categorySelect === 0) return this.getCourses();
      axios
        .get(`${url}/courseTags/${this.categorySelect}`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          this.coursesQuery = response.data;
          this.closeCategoryRegisterDialog();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openCategoryRegisterDialog() {
      this.categoryRegisterDialog = true;
    },
    openFileDialog(course) {
      this.form = Object.assign({}, course);
      this.getFilesCourse(this.form.id);
      this.fileDialog = true;
      setTimeout(() => {
        this.$refs.fileForm.reset();
      }, 100);
    },
    async getFilesCourse(id) {
      try {
        let res = await axios.get(`${url}/filesCourses/byCourse/${id}`, {
          headers: {
            authorization: token,
          },
        });
        this.files = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async registerFile() {
      let validation = this.$refs.fileForm.validate();

      try {
        if (validation) {
          let body = {
            courseId: this.form.id,
            description: this.formFile.description,
            uri: this.formFile.uri,
          };
          await axios.post(`${url}/filesCourses`, body, {
            headers: {
              authorization: token,
            },
          });
          setTimeout(() => {
            this.$refs.fileForm.reset();
          }, 100);
          this.getFilesCourse(this.form.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    insert1(event) {
      this.courseAssembly.splice(event.index, 0, event.data);
    },
    remove(array, value) {
      let index = array.indexOf(value);
      array.splice(index, 1);
    },
    getClassByKits() {
      axios
        .get(`${url}/classes/kit/${this.kitId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getKits() {
      axios
        .get(`${url}/basics/kits`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.kits = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getClasses() {
      axios
        .get(`${url}/classes`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;

          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    registerCourseAssembly() {
      let validation = this.$refs.linkForm.validate();

      if (validation) {
        this.loadingSend = true;
        if (this.courseAssembly == 0) {
          this.ordemAula = 1;
        }
        let lastItem = this.courseAssembly.length - 1;
        if (this.ordemAula == null || this.ordemAula == "") {
          this.ordemAula = this.courseAssembly[lastItem].order + 1;
        }

        const data = {
          fk_courseId: this.form.id,
          fk_classId: this.classId,
          order: this.ordemAula,
        };

        let idsCourseAssembly = [];
        this.courseAssembly.forEach((element) => {
          idsCourseAssembly.push(element.Class.id);
        });
        let bolIdsCourse = idsCourseAssembly.indexOf(this.classId);
        if (bolIdsCourse === -1) {
          axios
            .post(`${url}/courseAssembly`, data, {
              headers: {
                authorization: token,
              },
            })
            .then(() => {
              let idsAula = [];
              this.courseAssembly.forEach((element) => {
                idsAula.push(element.fk_classId);
              });
              idsAula.splice(this.ordemAula - 1, 0, this.classId);
              let body = {
                order: idsAula,
              };

              axios
                .put(`${url}/courseAssembly/${this.form.id}`, body, {
                  headers: {
                    authorization: token,
                  },
                })
                .then(() => {
                  this.getCourseAssemblyById(this.form.id);
                })
                .catch((error) => {
                  console.error(error);
                });

              this.ordemAula = null;
              this.nameClass = null;
              this.loadingSend = false;
            })
            .catch((error) => {
              this.loadingSend = false;
              console.error(error);
            });
        } else {
          alert(this.$t('alerta.alerta_aula'));
          this.ordemAula = null;
          this.nameClass = null;
          this.loadingSend = false;
        }
      }
    },
    deleteAssemblyCourse(item) {
      this.assemblySelectDelete = item;
      this.dialogCourseAssemblyDelete = true;
    },

    openAddDialog(register) {
      this.classes = []
      //console.log(register)
      this.form = Object.assign({}, register);
      //console.log(this.form)
      this.getCourseAssemblyById(this.form.id);
      this.assemblyDialog = true;
    },
    getCourseAssemblyById(courseId) {
      axios
        .get(`${url}/courseAssembly/testando/${courseId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courseAssembly = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    courseAssemblyDelete() {
      let assemblyId = this.assemblySelectDelete.id;
      axios
        .delete(`${url}/courseAssembly/${assemblyId}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.dialogCourseAssemblyDelete = false;
          let idsAula = [];
          this.courseAssembly.forEach((element) => {
            if (element.fk_classId != this.assemblySelectDelete.fk_classId) {
              idsAula.push(element.fk_classId);
            }
          });

          let body = {
            order: idsAula,
          };

          axios
            .put(`${url}/courseAssembly/${this.form.id}`, body, {
              headers: {
                authorization: token,
              },
            })
            .then(() => {
              this.getCourseAssemblyById(this.form.id);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async saveAssembly() {
      this.loading = true;
      let courseId = this.form.id;

      await axios.delete(`${url}/courseAssembly/testando/${courseId}`, {
        headers: {
          authorization: token,
        },
      });

      for (let index = 0; index < this.courseAssembly.length; index++) {
        const element = this.courseAssembly[index];
        element.order = index;
        await axios.post(`${url}/courseAssembly`, element, {
          headers: {
            authorization: token,
          },
        });
      }
      this.successSnackbar = true;
      this.loading = false;
      this.closeLink();
    },
    deleteCourse(register) {
      let courseId = register.id;
      axios
        .delete(
          `${url}/courses/${courseId}`,
          {
            headers: {
              authorization: token,
            },
          },
          this.form
        )
        .then(() => {
          this.deleteDialog = false;
          this.successSnackbar = true;
          this.getCourses();
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
    },
    async registerThumb() {
      let formData = new FormData();
      formData.append("file", this.thumb, this.thumb.name);
      await axios
        .post(`${url}/courses/files`, formData, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          let link = response.data.url;
          this.form.thumbnail = link;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
    async updateCourse() {
      await axios
        .put(`${url}/courses/${this.form.id}`, this.form, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          if (this.form.categoryCoursesId) {
            let data = {
              categoryId: this.form.categoryCoursesId,
              courseId: this.form.id,
            };
            axios.post(`${url}/courseTags`, data, {
              headers: {
                authorization: token,
              },
            });
          }
          this.getCourses();
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
    },

    cancel() {
      this.$refs.coursesForm.reset();
      this.registerDialog = false;
      this.editMode = false;
      this.assemblyDialog = false;
    },
    editCourse(register) {
      this.form = Object.assign({}, register);
      this.registerDialog = true;
      this.editMode = true;
    },
    getCourses() {
      axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          this.coursesQuery = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.coursesForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerThumb();
            await this.registerCourse();
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch (error) {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            if (this.thumb) {
              await this.registerThumb();
            }
            await this.updateCourse();
          } catch (error) {
            this.errorSnackbar = true;
          }
        }
        this.$refs.coursesForm.reset();
        this.registerDialog = false;
        this.editMode = false;
      }
    },
    async registerCourse() {
      axios
        .post(`${url}/courses`, this.form, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          if (this.form.categoryCoursesId) {
            let data = {
              categoryId: this.form.categoryCoursesId,
              courseId: res.data.success.id,
            };
            axios.post(`${url}/courseTags`, data, {
              headers: {
                authorization: token,
              },
            });
          }
          this.getCourses();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeLink() {;
      this.$refs.linkForm.reset();
      this.assemblyDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.drop-allowed.drop-in * {
  cursor: inherit !important;
}

.pointer {
  cursor: pointer;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>