<template>
  <div class="ticket-support">
    <!-- Barra lateral com lista de tickets -->
    <div class="sidebar">
      <div class="sidebar-header">Tickets</div>
      <ul>
        <TicketListItem
          v-for="ticket in tickets"
          :key="ticket.id"
          :ticket="ticket"
          :isSelected="selectedTicket && selectedTicket.id === ticket.id"
          @click="selectTicket(ticket)"
        />
      </ul>
    </div>

    <!-- Área principal com as mensagens do ticket selecionado -->
    <div class="main-content">
      <ChatDetail
        v-if="selectedTicket"
        :ticket="selectedTicket"
        :user="user"
        @sendMessage="sendMessage"
        @closeSupport="closeSupport"
      />
      <div v-else class="no-selection">
        Selecione um ticket para visualizar a conversa
      </div>
    </div>
  </div>
</template>

<script>
import TicketListItem from "./TicketListItem.vue";
import ChatDetail from "./ChatDetail.vue";

const axios = require("axios");
import { DateTime } from 'luxon';
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  components: {
    TicketListItem,
    ChatDetail,
  },
  data() {
    return {
      tickets: [],
      ticketsData: [], 
      selectedTicket: null,
      user: {
        id: 12, 
        role: 1, 
        username: 'Suporte'
      }
    };
  },
  mounted() {
    this.decodeToken();
    this.getTickets();

    // Carregue o script mqtt.min.js
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mqtt/4.2.8/mqtt.min.js';
    script.onload = this.connectMQTT;
    document.head.appendChild(script);
  },
  methods: {
    connectMQTT() {
        // Verifique se MQTT está disponível
      if (window.mqtt) {
        // Conectar ao broker MQTT via WebSocket
        const mqtt_broker = process.env.VUE_APP_MQTT_BROKER;
        const options = {
            username: process.env.VUE_APP_MQTT_USER,
            password: process.env.VUE_APP_MQTT_PASSWORD
        };
        // Conectar ao broker MQTT via WebSocket
        this.mqttClient = window.mqtt.connect(mqtt_broker, options);

        this.mqttClient.on('connect', () => {
          //console.log('Conectado ao broker MQTT');

          // Inscreva-se no tópico do ticket
          const topic = `tickets/new`;
          this.mqttClient.subscribe(topic, (err) => {
            if (!err) {
              //console.log(`Inscrito no tópico: ${topic}`);
            } else {
              console.error(`Erro ao se inscrever no tópico: ${topic}`, err);
            }
          });
        });

        this.mqttClient.on('message', (topic2, message2) => {
            const parsedMessage = JSON.parse(message2.toString());
            const topic = `tickets/${parsedMessage.ticketId}/messages/new`;
            this.mqttClient.subscribe(topic, (err) => {
                if (!err) {
                //console.log(`Inscrito no tópico: ${topic}`);
                } else {
                    console.error(`Erro ao se inscrever no tópico: ${topic}`, err);
                }
            });
            if (this.selectedTicket && parsedMessage.ticketId == this.selectedTicket.id) {
                this.getTicketMessages();
            }
            else {
                this.getTickets();
            }
        });

        this.mqttClient.on('error', (err) => {
          console.error('Erro MQTT:', err);
        });
      } else {
        console.error('Biblioteca MQTT não carregada.');
      }
    },
    selectTicket(ticket) {
      this.selectedTicket = ticket;
      this.getTicketMessages();
    },
    async sendMessage(messageText) {
      if (this.selectedTicket) {
        var message = {
            idticket: this.selectedTicket.id,
            iduser: this.user.id,
            idusertype: this.user.role,
            message: messageText
        };
        await axios.post(`${url}/ticketMessages`, message, authorization);
        this.getTicketMessages();
      }
    },
    async closeSupport() {
        let ticket = this.ticketsData.find(t => t.idticket === this.selectedTicket.id);
        let ticketData = {
            id: ticket.idticket, 
            idusers: ticket.idusers, 
            idusertype: ticket.idusertype, 
            closedAt: DateTime.now()
        };
        ticket.closedAt = DateTime.now();
        this.selectedTicket.closedAt = DateTime.now();
        await axios.put(`${url}/tickets/${ticketData.id}`, ticketData, authorization);
        await this.sendMessage("Atendimento finalizado");
    },
    getTickets() {
      axios
        .get(`${url}/ticketsDetails`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          // Aqui você processa os dados retornados para formatar como necessário
          const data = res.data;

          this.ticketsData = data;

          const tickets = data.map((ticket) => ({
            id: ticket.idticket,
            userName: ticket.userName,
            role: ticket.type, 
            closedAt: null, 
            messages: [],
          }));

          // Exemplo de como você poderia armazenar os tickets no estado/componente
          this.tickets = tickets;

          //console.log('Tickets:', this.tickets);
        })
        .catch((err) => {
          console.error('Erro ao buscar tickets:', err);
        });
    },
    getTicketMessages() {
        axios
            .get(`${url}/tickets/${this.selectedTicket.id}`, {
            headers: {
                authorization: token,
            },
            })
            .then((res) => {
                // Aqui você processa os dados retornados para formatar como necessário
                const data = res.data;

                // Verifica se o ticket já está na lista de tickets
                let ticket = this.tickets.find(t => t.id === this.selectedTicket.id);

                data.forEach(item => {
                    // Verifica se a mensagem já existe no ticket
                    const existingMessage = ticket.messages.some(msg => msg.id == item.idmessage);
                    if (!existingMessage) {
                            ticket.messages.push({
                            id: item.idmessage,
                            user: item.user,
                            text: item.text,
                            iduser: item.iduser
                        });
                    }
                });

            })
            .catch((err) => {
            console.error('Erro ao buscar mensagens do ticket:', err);
            });
    },

    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Erro ao carregar o script: ${src}`));
        document.head.appendChild(script);
      });
    },
    async decodeToken() {
      try {
        await this.loadScript('https://cdn.jsdelivr.net/npm/jwt-decode@3.1.2/build/jwt-decode.min.js');
        const token = localStorage.getItem("accessToken");
        if (token) {
          try {
            const decodedToken = window.jwt_decode(token);
            this.user = decodedToken;
          } catch (error) {
            console.error("Erro ao decodificar o token:", error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>

<style scoped>
.ticket-support {
  display: flex;
  height: 100%;
}

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.sidebar-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.no-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #888;
}
</style>
