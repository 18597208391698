<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="green" class="text-h4">mdi-account-tie</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('login.login_usuario.usuario6') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" dark rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('admin.admin_novoadministrador') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formClient.name"
                      :label="$t('label.label_nome')"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formClient.username"
                      :rules="[rules.required, rules.user, rules.exist]"
                      :label="$t('label.label_usuario')"
                      required
                      maxlength="25"
                      counter
                      @change="verifyUser(formClient.username)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      :label="$t('label.label_email.email')"
                      :rules="[rules.email]"
                      v-model="formClient.email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formClient.phone"
                      :rules="[rules.none]"
                      :label="$t('label.label_celular.celular_inserir')"
                      v-mask="'(##) #########'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!editMode">
                    <v-text-field
                      color="blue"
                      v-model="formClient.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('label.label_pesquisa')"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="clients"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            outlined
            rounded
            color="darkpink"
            class="px-2 mx-1"
            @click="addOpen(item)"
          >
            <v-icon small class="mr-1">mdi-folder-open </v-icon>
            {{ $t('admin.admin_menu.menu2') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="darkblue"
            class="px-2 mx-1"
            @click="editItem(item)"
          >
            <v-icon small class="mr-1"> mdi-pencil </v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="error"
            class="px-2 mx-1"
            @click="deleteItem(item)"
          >
            <v-icon small class="mr-1"> mdi-delete </v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogAddCourse"
      :width="$vuetify.breakpoint.mdAndUp ? '45%' : '90%'"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title class="text-h5 darkblue white--text"
          >{{ $t('admin.admin_permissaocurso') }}</v-card-title
        >

        <v-card-text class="mt-6">
          <!-- <v-row>
            <v-col cols="12" sm="6" v-for="cour in courses" :key="cour.id">
              <v-row>
                <v-checkbox
                  :label="cour.name"
                  color="darkblue"
                  :value="cour.id"
                >
                </v-checkbox>

                <v-avatar right class="ml-2" size="60">
                  <v-img :src="cour.thumbnail"></v-img>
                </v-avatar>
              </v-row>
            </v-col>
          </v-row> -->

          <v-autocomplete
            v-model="friends"
            :items="courses"
            class="my-4"
            chips
            item-color="darkblue"
            color="blue"
            :label="$t('label.label_permissao')"
            :no-data-text="$t('admin.admin_nenhumcurso')"
            item-text="name"
            item-value="name"
            :placeholder="$t('admin.admin_selecionecurso')"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                active-class="blue--text text--accent-4"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar left>
                  <v-img :src="data.item.thumbnail"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darkpink" text @click="closeAdd()"> {{ $t('botoes.botao_cancelar') }} </v-btn>
          <v-btn color="darkpink" text @click="savePermission()">
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      userExisting: false,
      dialog: null,
      showPassword: false,
      formClient: {},
      search: "",
      headers: [
        { text: this.$t('login.login_usuario.usuario6'), value: "name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "username" },
        { text: this.$t('label.label_email.email'), value: "email" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      clients: [],
      friends: [],
      courses: [],
      dialogAddCourse: null,
      editedIndex: -1,
      dialogDelete: null,
      errorSnackbar: false,
      successSnackbar: false,
      snackbar: false,
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        // userExist: (v) =>
        //   (v && !this.checkUserExistence(v)) || "Nome de usuário já existe",

        none: true,
      },
      nameSchool: null,
      schoolName: [],
      schools: [],
    };
  },
  methods: {
    getClient() {
      axios
        .get(`${url}/clients`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.clients = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    registerClient() {
      axios
        .post(`${url}/clients`, this.formClient, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getClient();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateClient(id) {
      axios
        .put(`${url}/clients/${id}`, this.formClient, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getClient();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    savePermission() {
      let coursesIds = [];
      for (let i = 0; i < this.friends.length; i++) {
        for (let j = 0; j < this.courses.length; j++) {
          if (this.friends[i] == this.courses[j].name) {
            coursesIds.push(this.courses[j].id);
          }
        }
      }
      const data = { clientId: this.editedIndex, courseId: coursesIds };

      axios
        .post(`${url}/clientCoursePermissions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeAdd();
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeAdd() {
      this.friends = [];
      this.dialogAddCourse = false;
    },
    addOpen(item) {
      this.friends = [];
      this.editedIndex = item.id;
      this.dialogAddCourse = true;
      axios
        .get(`${url}/clientCoursePermissions?clientId=${item.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.forEach((element) => {
            this.friends.push(element.Course.name);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async saveBtn() {
      let validation = this.$refs.form.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerClient(this.formClient);

            setTimeout(() => {
              this.editMode = false;

              this.successSnackbar = true;
              // this.$refs.form.reset();
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateClient(this.formClient.id);
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.form.reset();
        this.dialog = false;
      }
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/clients/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getClient();
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          this.errorSnackbar = true;
        });
    },
    getcourses() {
      axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.form.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formClient = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formClient = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formClient = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    // async checkUserExistence(v) {
    //   let response = await axios.get(`${url}/clients/user?user=${v}`, {
    //     headers: {
    //       authorization: token,
    //     },
    //   });
    //   this.userExisting = response.data;
    //   if (this.userExisting == null) {

    //     return this.userExisting === v;
    //   } else {

    //     return this.userExisting.username === v;
    //   }
    // },
  },
  created() {
    this.getClient();
    this.getcourses();
  },
};
</script>
